import { Suspense } from 'react';

import routes from '../routes';
import { renderRoutes } from 'react-router-config';

const OverlayLoading = () => (
    <div className="overlay-wrapper ">
        <div className="overlay">
            <i className="fa fa-refresh fa-spin"></i>
        </div>
    </div>
);

const Content = () => (
    <div className="content-wrapper">
        <Suspense fallback={<OverlayLoading />}>
            {renderRoutes(routes)}
        </Suspense>
    </div>
);

export default Content;
