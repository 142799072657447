import { push } from 'connected-react-router';
import axios from 'axios';

import { SET_POPUP } from '../actionTypes/popupActionTypes';
import { POPUP_TYPE } from '../reducers/popupReducer';
import ActionTypes from '../actionTypes/aceActionTypes';
import { emit } from '../socket';

const actions = {
    syncAclUsers() {
        return (dispatch) => {
            dispatch({ type: ActionTypes.LOADING });
            emit({
                type: ActionTypes.SYNC_ACL_USERS,
                rType: ActionTypes.SET_DATA,
                eType: ActionTypes.ERROR,
            });
        };
    },
    fetchAclUsers() {
        return (dispatch) => {
            dispatch({ type: ActionTypes.LOADING });
            emit({
                type: ActionTypes.FETCH_ACL_USERS,
                rType: ActionTypes.SET_DATA,
                eType: ActionTypes.ERROR,
            });
        };
    },
    execRedis(cmd) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.LOADING });
            emit({
                type: ActionTypes.EXEC_REDIS,
                rType: ActionTypes.SET_DATA,
                eType: ActionTypes.ERROR,
                args: cmd,
            });
        };
    },
    fetchPlatformGames() {
        return (dispatch) => {
            dispatch({ type: ActionTypes.LOADING });
            emit({
                type: ActionTypes.FETCH_PLATFORM_GAMES,
                rType: ActionTypes.SET_DATA,
                eType: ActionTypes.ERROR,
            });
        };
    },
    fetchGames() {
        return (dispatch) => {
            dispatch({ type: ActionTypes.LOADING });
            emit({
                type: ActionTypes.FETCH_GAMES,
                rType: ActionTypes.SET_DATA,
                eType: ActionTypes.ERROR,
            });
        };
    },
    saveGame(game) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.LOADING });
            emit({
                type: ActionTypes.SAVE_GAME,
                rType: ActionTypes.SET_DATA,
                eType: ActionTypes.ERROR,
                args: game,
            });
        };
    },
    deleteGame(gid) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.LOADING });
            emit({
                type: ActionTypes.DELETE_GAME,
                rType: ActionTypes.SET_DATA,
                eType: ActionTypes.ERROR,
                args: gid,
            });
        };
    },
    fetchGiftPacks() {
        return (dispatch) => {
            dispatch({ type: ActionTypes.LOADING });
            emit({
                type: ActionTypes.FETCH_GIFT_PACKS,
                rType: ActionTypes.SET_DATA,
                eType: ActionTypes.ERROR,
            });
        };
    },
    saveGiftPack(form, cb) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.LOADING });
            emit(
                {
                    type: ActionTypes.SAVE_GIFT_PACK,
                    rType: ActionTypes.SET_DATA,
                    eType: ActionTypes.ERROR,
                    args: form,
                },
                cb
            );
        };
    },
    deleteGiftPack(id) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.LOADING });
            emit({
                type: ActionTypes.DELETE_GIFT_PACK,
                rType: ActionTypes.SET_DATA,
                eType: ActionTypes.ERROR,
                args: id,
            });
        };
    },
    uploadFile({ key, value }) {
        const formData = new FormData();
        formData.append('upload', value);

        const authOptions = {
            method: 'POST',
            url: '/api/upload/image',
            data: formData,
            headers: {
                'content-type': 'multipart/form-data',
            },
        };

        return (dispatch) => {
            dispatch({
                type: ActionTypes.LOADING,
            });

            return axios(authOptions)
                .then((response) => {
                    const { url } = response.data;
                    dispatch({
                        type: ActionTypes.SET_DATA,
                        data: { uploadImage: { [key]: url } },
                    });
                })
                .catch((error) => {
                    const { message } = error.response.data;

                    dispatch({
                        type: ActionTypes.ERROR,
                        data: { error: message },
                    });

                    dispatch({
                        type: SET_POPUP,
                        data: { type: POPUP_TYPE.ERROR, message },
                    });
                });
        };
    },
};

export default actions;
