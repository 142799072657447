import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory as createHistory } from 'history';
import { createLogger } from 'redux-logger';
import rootReducer from '../reducers';

export const history = createHistory();

// const enhancers = [];
const middleware = [
    thunk,
    routerMiddleware(history),
];

// Configure the logger middleware
const logger = createLogger({
    level: 'info',
    collapsed: true,
});

if (process.env.NODE_ENV === 'development') {
    // const devToolsExtension = window.devToolsExtension;

    // if (typeof devToolsExtension === 'function') {
    //   enhancers.push(devToolsExtension());
    // }
    middleware.push(logger);
}

const createStoreWithMiddleware = compose(applyMiddleware(...middleware)(createStore));

export default function configure(initialState) {
    // Create the redux store and add middleware to it
    const configStore = createStoreWithMiddleware(
        rootReducer(history),
        initialState,
    );

    // Snippet to allow hot reload to work with reducers
    // if (module.hot) {
    //     module.hot.accept(function _() {
    //         configStore.replaceReducer(rootReducer);
    //     });
    // }

    return configStore;
}

// export default function configureStore(preloadedState) {
//   const store = createStore(
//     createRootReducer(history), // root reducer with router state
//     preloadedState,
//     compose(
//       applyMiddleware(
//         routerMiddleware(history), // for dispatching history actions
//         // ... other middlewares ...
//       ),
//     ),
//   )

//   return store
// }
