const ActionTypes = {
    LOADING: 'eventPoint.loading',
    ERROR: 'eventPoint.error',
    SET_DATA: 'eventPoint.set_data',
    SET_EVENT: 'eventPoint.set_event',

    FETCH_POINT_EVENTS: 'eventPoint.fetchPointEvents',
    FIND_POINT_EVENT_BY_ID: 'eventPoint.findPointEventById',
    ADD_POINT_EVENT: 'eventPoint.addPointEvent',
    UPDATE_POINT_EVENT: 'eventPoint.updatePointEvent',
    DELETE_POINT_EVENT: 'eventPoint.deletePointEvent',
    UPLOAD_POINT_EVENT_FILE: 'eventPoint.uploadFile',
};

module.exports = ActionTypes;
