const ActionTypes = {
    LOADING: 'ace.loading',
    ERROR: 'ace.error',
    SET_DATA: 'ace.set_data',
    SET_DETAIL_DATA: 'ace.set_detail_data',

    SYNC_ACL_USERS: 'ace.syncAclUsers',
    FETCH_ACL_USERS: 'ace.fetchAclUsers',

    EXEC_REDIS: 'ace.execRedis',

    FETCH_PLATFORM_GAMES: 'ace.fetchPlatformGames',
    FETCH_GAMES: 'ace.fetchGames',
    SAVE_GAME: 'ace.saveGame',
    DELETE_GAME: 'ace.deleteGame',

    FETCH_GIFT_PACKS: 'ace.fetchGiftPacks',
    SAVE_GIFT_PACK: 'ace.saveGiftPack',
    DELETE_GIFT_PACK: 'ace.deleteGiftPack'
};

module.exports = ActionTypes;
