const ActionTypes = {
    LOADING: 'banner.loading',
    ERROR: 'banner.error',
    SET_DATA: 'banner.set_data',

    FETCH_BANNERS: 'banner.fetchBanners',
    FETCH_BANNER: 'banner.fetchBanner',
    SAVE_BANNER: 'banner.saveBanner',
    SAVE_BANNER_PRIORITY: 'banner.saveBannerPriority',
    DELETE_BANNER: 'banner.deleteBanner',
};

module.exports = ActionTypes;
