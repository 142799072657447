const ActionTypes = {
    LOADING: 'seafaring.loading',
    ERROR: 'seafaring.error',
    SET_DATA: 'seafaring.set_data',

    FETCH_TOKEN_CHANGED: 'seafaring.fetchTokenChanged',
    FETCH_AWARD_DELIVERY: 'seafaring.fetchAwardDelivery',
    FETCH_RANK_LIST: 'seafaring.fetchRankList',
};

module.exports = ActionTypes;
