const ActionTypes = {
    SET_LOGIN_USER: 'auth.set_login_user',
    LOADING: 'auth.loading',
    ERROR: 'auth.error',
    SET_DATA: 'auth.set_data',
    LOGIN: 'auth.login',

    CHECK_LOGIN: 'auth.checkLogin',
    LOGOUT: 'auth.logout',
    FETCH_USER_FUNCTIONS: 'auth.fetchUserFunctions',
    FETCH_USERS: 'auth.fetchUsers',
    TOGGLE_USER_STATUS: 'auth.toggleUserStatus',
    ADD_USER_X_ROLE: 'auth.addUserXRole',
    DELETE_USER_X_ROLE: 'auth.deleteUserXRole',
    ADD_ROLE_X_FUNCTION: 'auth.addRoleXFunction',
    DELETE_ROLE_X_FUNCTION: 'auth.deleteRoleXFunction',

    UPDATE_PASSWORD: 'auth.updatePassword'
};

module.exports = ActionTypes;
