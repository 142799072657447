const ActionTypes = require('../actionTypes/postActionTypes');

export const initState = {
    loading: false,
    error: null,
    data: null,
    posts: [],
    post: {
        subject: '',
        content: '',
        status: 'on',
        type: 'annc',
        displayTime: '',
    },
    uploadImage1: null,
    uploadImage2: null,
};

function reducer(state = initState, action) {
    let newState = {};
    Object.assign(newState, state);
    switch (action.type) {
        case ActionTypes.LOADING:
            newState.loading = true;
            newState.error = null;
            newState.message = null;
            break;
        case ActionTypes.ERROR:
            newState.loading = false;
            newState.error = action.data;
            alert(action.data);
            break;
        case ActionTypes.SET_DATA:
            newState.loading = false;
            newState = { ...newState, ...action.data };
            break;
        default:
            break;
    }

    return newState;
}

export default reducer;
