import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import authAction from '../actions/authAction';

const ControlSidebar = ({ logout }) => {

    const dispatch = useDispatch();

    const onLogout = () => {
        authAction.logout()(dispatch);
    };

    return (
        <aside className="control-sidebar control-sidebar-dark">
            <div className="p-3">
                {/* <h5>Title</h5> */}
                <p><button className="btn btn-block btn-outline-secondary" onClick={onLogout}>Logout</button></p>
                <p><Link to="/adm/edit-password" className="btn btn-block btn-outline-secondary" >Change Password</Link></p>
            </div>
        </aside>
    );
};

export default ControlSidebar;
