const ActionTypes = {
    LOADING: 'party3.loading',
    ERROR: 'party3.error',
    SET_DATA: 'party3.set_data',

    UPLOAD_FILE: 'party3.uploadFile',
    FETCH_POINTS_CHANGE: 'party3.fetchPointsChange',
    FETCH_ITEM_DELIVERED: 'party3.fetchItemDelivered',
    FETCH_RANK_LIST: 'party3.fetchRankList',
};

module.exports = ActionTypes;
