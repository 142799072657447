const ActionTypes = {
    LOADING: 'bingoBall.loading',
    ERROR: 'bingoBall.error',
    SET_DATA: 'bingoBall.set_data',

    FETCH_BINGO_BALL_CHANGED: 'bingoBall.fetchBingoBallChanged',
    FETCH_ITEM_DELIVERED: 'bingoBall.fetchItemDelivered',
    FETCH_LEADER_BOARD: 'bingoBall.fetchLeaderBoard',

};

module.exports = ActionTypes;
