const ActionTypes = require('../actionTypes/productActionTypes');

export const initState = {
    loading: false,
    error: null,
    data: null,
    products: [],
    product: {
        name: '',
        status: 'on',
        content: '',
        html: '',
        price: '',
        saleChannels: [],
        buySpots: []
    },
    uploadImage1: null,
    uploadImage2: null,
    uploadImage3: null,
};

function reducer(state = initState, action) {
    let newState = {};
    Object.assign(newState, state);
    switch (action.type) {
        case ActionTypes.LOADING:
            newState.loading = true;
            newState.error = null;
            newState.message = null;
            break;
        case ActionTypes.ERROR:
            newState.loading = false;
            newState.error = action.data;
            alert(action.data);
            break;
        case ActionTypes.SET_DATA:
            newState.loading = false;
            newState = { ...newState, ...action.data };
            break;
        default:
            break;
    }

    return newState;
}

export default reducer;
