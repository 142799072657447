/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useSelector } from 'react-redux';

const Header = () => {

    const user = useSelector((state) => state.authReducer.user);

    return (
        <nav className="main-header navbar navbar-expand navbar-white navbar-light text-sm">
            {/* <!-- Left navbar links --> */}
            <ul className="navbar-nav ">
                <li className="nav-item">
                    <a href="#" className="nav-link" data-widget="pushmenu" role="button"><i className="fas fa-bars"></i></a>
                </li>
                {/* <li className="nav-item d-none d-sm-inline-block">
          <a href="index3.html" className="nav-link">Home</a>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
          <a href="#" className="nav-link">Contact</a>
        </li> */}
            </ul>

            {/* <!-- Right navbar links --> */}
            <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                    <a href="#" className="nav-link" data-widget="control-sidebar" data-slide="true" role="button">
                        {/* <i className="fas fa-th-large"></i> */}
                        {user && user.name}
                    </a>
                </li>
            </ul>
        </nav>
    );
};

export default Header;
