/* global $ */
import React, { useState, useEffect, useRef } from 'react';
import { POPUP_TYPE } from '../../reducers/popupReducer';

const PopupModal = (props) => {
    const { action, loading, isOpen, onClose, title, children, type } = props;
    const [element, setElement] = useState();
    const popupModal = useRef(null);

    const BG_CLASS_MAP = {
        [POPUP_TYPE.ERROR]: 'bg-danger',
        [POPUP_TYPE.CONFIRM]: 'bg-danger',
        [POPUP_TYPE.DEFAULT]: 'bg-success',
    };

    useEffect(() => {
        const $el = $(popupModal.current);
        $el.on('hidden.bs.modal', onClose);
        setElement($el);
    }, []);

    useEffect(() => {
        if (element) {
            if (isOpen) {
                element.modal('show');
            } else {
                element.modal('hide');
            }
        }
    }, [isOpen]);

    const onAction = (event) => {
        event.preventDefault();
        action();
        onClose();

        return false;
    };

    return (
        <form className="form-horizontal" onSubmit={onAction}>
            <div
                className="modal fade"
                id="staticBackdrop"
                data-backdrop="static"
                data-keyboard="false"
                tabIndex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
                ref={popupModal}
            >
                <div className="modal-dialog">
                    <div
                        className={`modal-content ${
                            type && BG_CLASS_MAP[type]
                        }`}
                    >
                        {/* <div className={'modal-content'}> */}
                        <div
                            className="overlay "
                            style={{
                                visibility: loading ? 'visible' : 'hidden',
                            }}
                        >
                            <i className="fas fa-2x fa-sync-alt fa-spin"></i>
                        </div>

                        <div className="modal-header">
                            <h5
                                className="modal-title"
                                id="staticBackdropLabel"
                            >
                                {title && title}
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">{children}</div>
                        {/* <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={onClose}>取消</button>
                            {action
                                && <button type="submit" className="btn btn-primary">確定</button>
                            }
                        </div> */}

                        <div className="modal-footer justify-content-between">
                            <button
                                type="button"
                                className={`btn ${
                                    type ? 'btn-outline-light' : 'btn-secondary'
                                }`}
                                data-dismiss="modal"
                                onClick={onClose}
                            >
                                {action ? '取消' : '關閉'}
                            </button>
                            {/* <button className="btn btn-default btn-flat" type="button" data-dismiss="modal">取消</button> */}
                            {action && (
                                <button
                                    type="submit"
                                    className={`btn ${
                                        type
                                            ? 'btn-outline-light'
                                            : 'btn-primary'
                                    }`}
                                >
                                    確定
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default PopupModal;
