const ActionTypes = {
    LOADING: 'mission.loading',
    ERROR: 'mission.error',
    SET_DATA: 'mission.set_data',

    UPLOAD_FILE: 'mission.uploadFile',
    FETCH_POINTS_CHANGE: 'mission.fetchPointsChange',
    FETCH_ITEM_DELIVERED: 'mission.fetchItemDelivered',
    FETCH_RANK_LIST: 'mission.fetchRankList',
};

module.exports = ActionTypes;
