import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const SideBar = () => {
    const functions = useSelector((state) => state.authReducer.functions);
    const [actFunc, setActFunc] = useState([]);

    useEffect(() => {
        if (functions && functions[0]) {
            setActFunc(functions[0].functions[0]);
        }
    }, [functions]);

    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            {/* <!-- Brand Logo --> */}
            <a href="/" className="brand-link">
                <img
                    src="/img/aceLogo.png"
                    alt="AdminLTE Logo"
                    className="brand-image img-circle elevation-3"
                    style={{ opacity: '.8' }}
                />
                <span className="brand-text font-weight-light">
                    {/* <span className="text-xl"> ♠ </span> */}
                    <b>Ace Club</b> Adm
                </span>
                {/* <span class="login-logo"><b>AceClub</b>Adm</span> */}
            </a>

            {/* <!-- Sidebar --> */}
            <div className="sidebar">
                <nav className="mt-2">
                    <ul
                        className="nav nav-pills nav-sidebar flex-column nav-compact"
                        data-widget="treeview"
                        role="menu"
                        data-accordion="true"
                    >
                        {functions &&
                            functions.map((func) => (
                                <ParentFunc
                                    key={func.id}
                                    func={func}
                                    funcs={func.functions}
                                    actFunc={actFunc}
                                    setActFunc={setActFunc}
                                />
                            ))}
                    </ul>
                </nav>
            </div>
        </aside>
    );
};

const LeaveFunc = ({ func, actFunc, setActFunc }) => {
    return (
        <li className="nav-item" onClick={() => setActFunc(func)}>
            <Link
                to={func.url}
                className={`nav-link ${
                    actFunc && actFunc.id === func.id ? 'active' : ''
                }`}
            >
                <i className="far fa-circle nav-icon" />
                <p>{func.name}</p>
            </Link>
        </li>
    );
};

const ParentFunc = ({ func, funcs, actFunc, setActFunc }) => {
    return (
        <li
            className={
                funcs.some(({ id }) => id === actFunc.id)
                    ? 'nav-item menu-open'
                    : 'nav-item'
            }
        >
            <div className="nav-link ">
                <i className="nav-icon fas fa-th" />
                <p>
                    {func.name}
                    <i className="right fas fa-angle-left" />
                </p>
            </div>
            <ul className="nav nav-treeview">
                {funcs &&
                    funcs.map((func) => (
                        <LeaveFunc
                            key={func.id}
                            func={func}
                            actFunc={actFunc}
                            setActFunc={setActFunc}
                        />
                    ))}
            </ul>
        </li>
    );
};

export default SideBar;
