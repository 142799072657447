const ActionTypes = {
    LOADING: 'egg.loading',
    ERROR: 'egg.error',
    SET_DATA: 'egg.set_data',

    FETCH_ITEM_DELIVERED: 'egg.fetchItemDelivered',
    FETCH_RANK_LIST: 'egg.fetchRankList',
};

module.exports = ActionTypes;
