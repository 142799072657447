const ActionTypes = {
    LOADING: 'gameInfo.loading',
    ERROR: 'gameInfo.error',
    SET_DATA: 'gameInfo.set_data',

    FETCH_GAMEINFOS: 'gameInfo.fetchGameInfos',
    FETCH_GAMEINFO: 'gameInfo.fetchGameInfo',
    SAVE_GAMEINFO: 'gameInfo.saveGameInfo',
    SAVE_BIGBANNER: 'gameInfo.saveGigBanner',
    CHANGE_PRIORITY: 'gameInfo.changePriority',
    SAVE_PRIORITY: 'gameInfo.savePriority',
    SAVE_GAME_HEADLINE: 'gameInfo.saveGameHeadline',
    FETCH_GAME_HEADLINES: 'gameInfo.fetchGameHeadlines',
};

module.exports = ActionTypes;
