const ActionTypes = {
    LOADING: 'bundle.loading',
    ERROR: 'bundle.error',
    SET_DATA: 'bundle.set_data',

    FETCH_BUNDLES: 'bundle.fetchBundles',
    FETCH_BUNDLE: 'bundle.fetchBundle',
    SAVE_BUNDLE: 'bundle.saveBundle',
};

module.exports = ActionTypes;
