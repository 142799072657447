const ActionTypes = require('../actionTypes/permissionActionTypes');

export const initState = {
    loading: false,
    error: null,
    data: null,
    users: [],
    roles: [],
    functions: [],
    userXRole: [],
    ruleXFunction: [],

    user: {
        account: '',
        name: '',
        email: '',
    },
    role: {
        name: ''
    },
    func: {
        name: '',
        url: null,
        displayOrder: null,
        status: null,
        parent: null,
        level: null
    }
};

function reducer(state = initState, action) {
    let newState = {};
    Object.assign(newState, state);
    switch (action.type) {
        case ActionTypes.LOADING:
            newState.loading = true;
            newState.error = null;
            newState.message = null;
            break;
        case ActionTypes.ERROR:
            newState.loading = false;
            newState.error = action.data;
            alert(action.data);
            break;
        case ActionTypes.SET_DATA:
            newState.loading = false;
            newState = { ...newState, ...action.data };
            break;
        default:
            break;
    }

    return newState;
}

export default reducer;
