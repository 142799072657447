const ActionTypes = {
    LOADING: 'bingoTask.loading',
    ERROR: 'bingoTask.error',
    SET_DATA: 'abingoTaskct.set_data',

    FETCH_BINGOTASKS: 'bingoTask.fetchBingoTasks',
    FETCH_BINGOTASK: 'bingoTask.fetchBingoTask',
    SAVE_BINGOTASK: 'bingoTask.saveBingoTask',
    FETCH_BINGO_TASK_SETTINGS: 'bingoTask.fetchBingoTasks',
    SAVE_BINGO_TASK_SETTINGS: 'bingoTask.saveBingoTasks',
};

module.exports = ActionTypes;
