const ActionTypes = {
    LOADING: 'gorichman.loading',
    ERROR: 'gorichman.error',
    SET_DATA: 'gorichman.set_data',

    FETCH_ITEM_DELIVERED_LOG: 'gorichman.fetchItemDeliveredLog',
    FETCH_USER_STATUS: 'gorichman.fetchUserStatus',
    RESET: 'gorichman.reset',
};

module.exports = ActionTypes;
