const ActionTypes = {
    LOADING: 'flash.loading',
    ERROR: 'flash.error',
    SET_DATA: 'flash.set_data',

    FETCH_GAME_PLAY_EVENTS: 'flash.fetchGamePlayEvents',
    FETCH_GAME_GAME_WAGER_COMPLETE_EVENTS: 'flash.fetchGameWagerCompleteEvents',
    FETCH_AWARD_LOGS: 'flash.fetchAwardLogs',
    FETCH_EARLYPLAY_AWARD_LOGS: 'flash.fetchEarlyPlayAwardLogs',
    FETCH_FEEDBACK2_AWARD_LOGS: 'flash.fetchFeedback2AwardLogs',
};

module.exports = ActionTypes;
