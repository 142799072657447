
const ActionTypes = require('../actionTypes/gameInforActionTypes');

export const initState = {
    loading: false,
    error: null,
    data: null,
    gameInfos: [],
    gameInfo: {
        gid: 0,
        subject: '',
        content: '',
        video: '',
        leftImg: '',
        rightImg: '',
        status: 'on',
        banner: '',
        imageBg: '',
        imageContentWeb: [],
        imageContentMobile: [],
    },
};

function reducer(state = initState, action) {
    let newState = {};
    Object.assign(newState, state);
    switch (action.type) {
        case ActionTypes.LOADING:
            newState.loading = true;
            newState.error = null;
            newState.message = null;
            break;
        case ActionTypes.ERROR:
            newState.loading = false;
            newState.error = action.data;
            alert(action.data);
            break;
        case ActionTypes.SET_DATA:
            newState.loading = false;
            newState = { ...newState, ...action.data };
            break;
        default:
            break;
    }

    return newState;
}

export default reducer;
