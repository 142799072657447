const ActionTypes = {
    LOADING: 'rank.loading',
    ERROR: 'rank.error',
    SET_DATA: 'rank.set_data',

    FETCH_AWARD_DELIVERED_LOG: 'rank.fetchAwardDeliveredLog',
    FETCH_RANK_LIST: 'rank.fetchRankList',
    FETCH_USER_BET_POINTS: 'rank.fetchUserBetPoints',
};

module.exports = ActionTypes;
