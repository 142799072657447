const ActionTypes = {
    LOADING: 'bingoConfig.loading',
    ERROR: 'bingoConfig.error',
    SET_DATA: 'bingoConfig.set_data',

    FETCH_BINGOCONFIGS: 'bingoConfig.fetchBingoConfigs',
    DELETE_BINGOCONFIG: 'bingoConfig.deleteBingoConfig',
    SAVE_BINGOCONFIG: 'bingoConfig.saveBingoConfig',
    // FETCH_BINGO_TASK_SETTINGS: 'bingoConfig.fetchBingoConfigs',
    // SAVE_BINGO_TASK_SETTINGS: 'bingoConfig.saveBingoConfigs',
};

module.exports = ActionTypes;
