const ActionTypes = require('../actionTypes/PointEventActionTypes');

export const initState = {
    loading: false,
    error: null,
    data: null,
    events: [],
    event: {
        type: null,
        content: '',
        beginTime: '',
        endTime: '',
    },
    tablePic: null,
    grabBag: null,
};

function reducer(state = initState, action) {
    let newState = {};
    Object.assign(newState, state);
    switch (action.type) {
        case ActionTypes.LOADING:
            newState.loading = true;
            newState.error = null;
            newState.message = null;
            break;
        case ActionTypes.ERROR:
            newState.loading = false;
            newState.error = action.data;
            break;
        case ActionTypes.SET_DATA:
            newState.loading = false;
            newState = { ...newState, ...action.data };
            break;
        case ActionTypes.SET_EVENT: {
            const func = action.func;
            const event = newState.event;
            newState.loading = false;
            if (event) {
                if (func && typeof func === 'function') {
                    newState.event = func(event);
                } else {
                    newState.event = {
                        ...newState.event,
                        ...action.data,
                    };
                }
            }
            break;
        }
        default:
            break;
    }

    return newState;
}

export default reducer;
