import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import Login from './components/Login';
import App from './components/App.js';

import './index.css';
import configure, { history } from './config/configure-store';
import { init as socketInit } from './socket';

const store = configure();
socketInit(store);

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <Switch>
                <Route path="/login">
                    <Login/>
                </Route>
                <Route path="/">
                    <App/>
                </Route>
            </Switch>
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root'),
);
