import axios from 'axios';
import ActionTypes from '../actionTypes/authActionTypes';
import { emit } from '../socket';

const actions = {
    login: ({ account, password, type, tokenId }) => (dispatch) => {
        dispatch({ type: ActionTypes.LOADING });
        dispatch({ type: ActionTypes.LOGIN });
        axios.post('/api/login', { account, password, type, tokenId }).then((res) => {
            window.location = '/';
            // const { code, data, message } = res.data;
            // if (code === 0) {
            //   dispatch({ type: ActionTypes.SET_DATA, data });
            // } else {
            //   dispatch({ type: ActionTypes.ERROR, data: message });
            // }
        }).catch((err) => {
            dispatch({ type: ActionTypes.ERROR, data: err.response.data.message });
        });
    },

    logout: () => (dispatch) => {
        dispatch({ type: ActionTypes.LOADING });
        emit({
            type: ActionTypes.LOGOUT,
            rType: ActionTypes.SET_DATA,
            eType: ActionTypes.ERROR,
        });
    },

    checkLogin: () => (dispatch) => {
        dispatch({ type: ActionTypes.LOADING });
        emit({
            type: ActionTypes.CHECK_LOGIN,
            rType: ActionTypes.SET_DATA,
            eType: ActionTypes.ERROR,
        });
    },

    updatePassword(form) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.LOADING });
            emit({
                type: ActionTypes.UPDATE_PASSWORD,
                rType: ActionTypes.SET_DATA,
                eType: ActionTypes.ERROR,
                args: { ...form },
            });
        };
    },
    fetchUserFunctions() {
        return (dispatch) => {
            dispatch({ type: ActionTypes.LOADING });
            emit({
                type: ActionTypes.FETCH_USER_FUNCTIONS,
                rType: ActionTypes.SET_DATA,
                eType: ActionTypes.ERROR,
            });
        };
    },

};

export default actions;
