const ActionTypes = {
    LOADING: 'moneyGod.loading',
    ERROR: 'moneyGod.error',
    SET_DATA: 'moneyGod.set_data',

    FETCH_ITEM_DELIVERED: 'moneyGod.fetchItemDelivered',
    FETCH_RANK_LIST: 'moneyGod.fetchRankList',
};

module.exports = ActionTypes;
