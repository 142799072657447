import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import authReducer from './authReducer';
import actReducer from './actReducer';
import aceReducer from './aceReducer';

import acePokerReducer from './acePokerReducer';
import acePoker2Reducer from './acePoker2Reducer';
import champagneReducer from './champagneReducer';
import bingoTaskReducer from './bingoTaskReducer';
import bingoConfigReducer from './bingoConfigReducer';
import flashReducer from './flashReducer';
import postReducer from './postReducer';
import gameInfoReducer from './gameInfoReducer';
import eventReducer from './eventReducer';
import bannerReducer from './bannerReducer';
import bundleReducer from './bundleReducer';
import productReducer from './productReducer';
import popupReducer from './popupReducer';
import permissionReducer from './permissionReducer';
import pointEventReducer from './pointEventReducer';
import reportReducer from './reportReducer';
import bingoBallReducer from './bingoBallReducer';
import bingo3Reducer from './bingo3Reducer';
import dragonReducer from './dragonReducer';
import eventInfoReducer from './eventInfoReducer';
import partyReducer from './partyReducer';
import party3Reducer from './party3Reducer';
import aceLotteryReducer from './aceLotteryReducer';
import gorichmanReducer from './gorichmanReducer';
import qaReducer from './qaReducer';
import luxuryReducer from './luxuryReducer';
import missionReducer from './missionReducer';
import registerReducer from './registerReducer';
import rankReducer from './rankReducer';
import superGiveReducer from './superGiveReducer';
import bossReducer from './bossReducer';
import spinReducer from './spinReducer';
import treasureReducer from './treasureReducer';
import worldCupReducer from './worldCupReducer';
import eggReducer from './eggReducer';
import moneyGodReducer from './moneyGodReducer';
import goldenTreeReducer from './goldenTreeReducer';
import seafaringReducer from './seafaringReducer';
import wishReducer from './wishReducer';

const createRootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        authReducer,
        aceReducer,
        bingoTaskReducer,
        bingoConfigReducer,
        popupReducer,
        permissionReducer,
        actReducer,
        acePokerReducer,
        acePoker2Reducer,
        champagneReducer,
        flashReducer,
        postReducer,
        gameInfoReducer,
        bannerReducer,
        bundleReducer,
        eventReducer,
        productReducer,
        pointEventReducer,
        reportReducer,
        bingoBallReducer,
        bingo3Reducer,
        dragonReducer,
        eventInfoReducer,
        partyReducer,
        party3Reducer,
        aceLotteryReducer,
        gorichmanReducer,
        qaReducer,
        luxuryReducer,
        missionReducer,
        registerReducer,
        rankReducer,
        superGiveReducer,
        bossReducer,
        spinReducer,
        treasureReducer,
        worldCupReducer,
        eggReducer,
        moneyGodReducer,
        goldenTreeReducer,
        seafaringReducer,
        wishReducer,
    });

export default createRootReducer;
