const ActionTypes = {
    SET_LOGIN_USER: 'permission.set_login_user',
    LOADING: 'permission.loading',
    ERROR: 'permission.error',
    SET_DATA: 'permission.set_data',

    FETCH_PERMISSIONS: 'permission.fetchPermissions',

    FETCH_USERS: 'permission.fetchUsers',
    FETCH_ROLES: 'permission.fetchRoles',
    FETCH_FUNCTIONS: 'permission.fetchFunctionss',

    FETCH_USER_X_ROLE: 'permission.fetchUserxXRole',
    FETCH_ROLE_X_FUNCTION: 'permission.fetchRoleXFunction',

    TOGGLE_USER_STATUS: 'permission.toggleUserStatus',
    ADD_USER_X_ROLE: 'permission.addUserXRole',
    DELETE_USER_X_ROLE: 'permission.deleteUserXRole',
    ADD_ROLE_X_FUNCTION: 'permission.addRoleXFunction',
    DELETE_ROLE_X_FUNCTION: 'permission.deleteRoleXFunction',

    SAVE_USER: 'permission.saveUser',
    SAVE_ROLE: 'permission.saveRole',
    SAVE_FUNCTION: 'permission.saveFunction',

};

module.exports = ActionTypes;
