const ActionTypes = {
    LOADING: 'olympic.loading',
    ERROR: 'olympic.error',
    SET_DATA: 'olympic.set_data',

    DELIVER_QUESTION_REWARDS: 'olympic.deliverQuestionReward',
    DELIVER_CONTEST_REWARDS: 'olympic.deliverContestReward',
};

module.exports = ActionTypes;
