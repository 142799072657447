const ActionTypes = {
    LOADING: 'acePoker.loading',
    ERROR: 'acePoker.error',
    SET_DATA: 'acePoker.set_data',

    FETCH_LEADER_BOARD: 'acePoker.fetchLeaderBoard',
    FETCH_CHIP_CHANGED_LOGS: 'acePoker.fetchChipChangedLogs',
    FETCH_ITEM_DELIVERED_LOGS: 'acePoker.fetchItemDeliveredLogs',

};

module.exports = ActionTypes;
