const ActionTypes = {
    LOADING: 'champagne.loading',
    ERROR: 'champagne.error',
    SET_DATA: 'champagne.set_data',
    RESET_DATA: 'champagne.reset_data',

    FETCH_ITEM_DELIVERED_LOGS: 'champagne.fetchItemDeliveredLogs',

};

module.exports = ActionTypes;
