import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Header from './Header';
import Sidebar from './Sidebar';
import Content from './Content';
import ControlSidebar from './ControlSidebar';
import authAction from '../actions/authAction';
import actAction from '../actions/actAction';
import aceAction from '../actions/aceAction';
import popupAction from '../actions/popupAction';
import PopupModal from './common/PopupModal';
import NewlineText from './common/NewlineText';
import { aceActionTypes } from '../actionTypes';
// import Footer from './Footer';

function App({ location }) {
    const user = useSelector((state) => state.authReducer.user);
    const { popup, isOpen } = useSelector((state) => state.popupReducer);

    const dispatch = useDispatch();

    useEffect(() => {
        authAction.checkLogin()(dispatch);
        // authAction.fetchUserFunctions()(dispatch);
        actAction.fetchAwardItems()(dispatch);

        // fetchActivities
        // fetchProducts
        // fetchGames (aceAction)
        actAction.fetchActivities()(dispatch);
        actAction.fetchProducts()(dispatch);
        aceAction.fetchGames()(dispatch);
    }, []);

    const onClose = () => {
        popupAction.hidePopup()(dispatch);
    };

    useEffect(() => {
        if (user) {
            console.log('user:', user);
        }
    }, [user]);

    if (user === null) {
        return (
            <Redirect to={{ pathname: '/login', state: { from: location } }} />
        );
    }

    // if (user.name) {
    return (
        <div className="wrapper">
            <PopupModal
                isOpen={isOpen}
                type={popup && popup.type}
                action={popup && popup.action}
                onClose={onClose}
            >
                <p className="text-center">
                    <NewlineText text={popup && popup.message} />
                </p>
            </PopupModal>

            <Header />
            <Sidebar />
            <Content />
            <ControlSidebar />
            {/* <Footer /> */}
        </div>
    );
    // }

    // return <div className="wrapper" />;
}

export default App;
