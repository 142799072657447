const ActionTypes = {
    LOADING: 'wish.loading',
    ERROR: 'wish.error',
    SET_DATA: 'wish.set_data',

    SAVE_WISH_PRESELECT: 'wish.saveWishPreselect',
    FETCH_TOKEN_CHANGED: 'wish.fetchTokenChanged',
    FETCH_RANK_LIST: 'wish.fetchRankList',
    DELETE_WINNERS: 'wish.deleteWinners',
    DELIVER_LOTTERY_REWARDS: 'wish.deliverLotteryReward',

    FETCH_BLACK_LIST: 'wish.fetchBlackList',
    SAVE_BLACK_LIST: 'wish.saveBlackList',
    DELETE_BLACK_LIST: 'wish.deleteBlackList',
};

module.exports = ActionTypes;
