const ActionTypes = {
    LOADING: 'bingo3.loading',
    ERROR: 'bingo3.error',
    SET_DATA: 'bingo3.set_data',

    FETCH_BINGO_BALL_CHANGED: 'bingo3.fetchBingoBallChanged',
    FETCH_ITEM_DELIVERED: 'bingo3.fetchItemDelivered',
    FETCH_LEADER_BOARD: 'bingo3.fetchLeaderBoard',
    FETCH_BINGO_BALL_OUGHT_TO: 'bingo3.fetchBingoBallOughtTo',
    RESET_BINGO_BALL_OUGHT_TO: 'bingo3.resetBingoBallOughtTo',
};

module.exports = ActionTypes;
