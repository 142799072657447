const ActionTypes = {
    LOADING: 'event.loading',
    ERROR: 'event.error',
    SET_DATA: 'event.set_data',

    FETCH_EVENTS: 'event.fetchEvents',
    FETCH_EVENT: 'event.fetchEvent',
    SAVE_EVENT: 'event.saveEvent',
};

module.exports = ActionTypes;
