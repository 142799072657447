const ActionTypes = {
    LOADING: 'act.loading',
    RESET: 'act.reset',
    ERROR: 'act.error',
    SET_DATA: 'act.set_data',
    SET_ACTIVITY_DATA: 'act.set_activity_data',
    SET_COPY_ACTIVITY: 'act.set_copy_activity',

    FETCH_ACTIVITIES: 'act.fetchActivities',
    FETCH_ACTIVITY: 'act.fetchActivity',
    DELETE_ACTIVITY: 'act.deleteActivity',

    SAVE_ACTIVITY: 'act.saveActivity',
    FETCH_BINGO_AWARD_SETTINGS: 'act.fetchBingoAwardSettings',
    SAVE_BINGO_AWARD_SETTINGS: 'act.saveBingoAwardSettings',

    FETCH_VIP_PAIR_RATES: 'act.fetchVipPairRates',
    SAVE_VIP_PAIR_RATES: 'act.saveVipPairRates',

    FETCH_VIP_AWARD_RATES: 'act.fetchVipAwardRates',
    SAVE_VIP_AWARD_RATES: 'act.saveVipAwardRates',

    FETCH_ACE_POKER2_VIP_AWARD_RATES: 'act.fetchAcePoker2VipAwardRates',
    SAVE_ACE_POKER2_VIP_AWARD_RATES: 'act.saveAcePoker2VipAwardRates',

    SYNC_ITEMS: 'act.syncItems',
    SYNC_PRODUCTS: 'act.syncProducts',

    SAVE_RAFFLE_PRESELECT: 'act.saveRafflePreselect',
    UPDATE_RAFFLE_PRESELECT: 'act.updateRafflePreselect',
    DELETE_RAFFLE_PRESELECT: 'act.deleteRafflePreselect',


    SET_DRAGON_TASK_DATA: 'act.set_dragon_task_data',

    DELETE_WINNERS: 'act.deleteWinners',

    SAVE_RAFFLE_TICKETS: 'act.saveRaffleTickets',
    FETCH_RAFFLE_TICKETS: 'act.fetchRaffleTickets',
    FETCH_RAFFLE_TICKETS_SIZE: 'act.fetchRaffleTicketsSize',

    UPDATE_RAFFLE_TOTAL_TICKETS_SIZE: 'act.updateRaffleTotalTicketsSize',

    FETCH_RAFFLE_BLACK_LIST: 'act.FETCH_RAFFLE_BLACK_LIST',
    SAVE_RAFFLE_BLACK_LIST: 'act.SAVE_RAFFLE_BLACK_LIST',
    DELETE_RAFFLE_BLACK_LIST: 'act.DELETE_RAFFLE_BLACK_LIST',

    FETCH_AWARD_ITEMS: 'act.fetchAwardItems',
    FETCH_PRODUCTS: 'act.fetchProducts',

};

module.exports = ActionTypes;
