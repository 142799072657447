const ActionTypes = {
    LOADING: 'goldenTree.loading',
    ERROR: 'goldenTree.error',
    SET_DATA: 'goldenTree.set_data',

    FETCH_ITEM_DELIVERED: 'goldenTree.fetchItemDelivered',
    FETCH_RANK_LIST: 'goldenTree.fetchRankList',
};

module.exports = ActionTypes;
