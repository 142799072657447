import React from 'react';
import { renderRoutes } from 'react-router-config';

const RoutesContainer = ({ route }) => {
    return <span>{renderRoutes(route.routes)}</span>;
};

const routes = [
    {
        path: '/',
        component: React.lazy(() => import('../components/act/Activities')),
        exact: true,
        breadcrumbName: '活動列表',
    },
    {
        path: '/act/activities/',
        component: RoutesContainer,
        breadcrumbName: '活動設定',
        routes: [
            {
                path: '/act/activities/',
                component: React.lazy(() =>
                    import('../components/act/Activities')
                ),
                exact: true,
                breadcrumbName: '活動設定',
            },
            {
                path: '/act/activities/copy/:id',
                component: React.lazy(() =>
                    import('../components/act/EditActivity')
                ),
                exact: true,
                breadcrumbName: '複製活動',
            },
            {
                path: '/act/activities/edit/:id',
                component: React.lazy(() =>
                    import('../components/act/EditActivity')
                ),
                exact: true,
                breadcrumbName: '編輯活動',
            },
            {
                path: '/act/activities/add/:type',
                component: React.lazy(() =>
                    import('../components/act/EditActivity')
                ),
                exact: true,
                breadcrumbName: '新增活動',
            },
        ],
    },

    {
        path: '/act/logs/activities/',
        component: RoutesContainer,
        breadcrumbName: '活動紀錄查詢',
        routes: [
            {
                path: '/act/logs/activities/',
                component: React.lazy(() =>
                    import('../components/act/Activities')
                ),
                breadcrumbName: '活動紀錄查詢',
                exact: true,
            },
            {
                path: '/act/logs/activities/:actId/',
                component: RoutesContainer,
                breadcrumbName: ':actName',
                noShow: true,
                routes: [
                    {
                        path: '/act/logs/activities/:actId/:type',
                        component: React.lazy(() =>
                            import('../components/act/LogNav')
                        ),
                        breadcrumbName: ':actName',
                        exact: true,
                    },
                ],
            },
        ],
    },

    {
        path: '/act/bingo/',
        component: RoutesContainer,
        // exact: true,
        noLink: true,
        breadcrumbName: '活動',
        routes: [
            {
                path: '/act/bingo/configs/',
                component: RoutesContainer,
                breadcrumbName: 'Bingo活動設定',
                routes: [
                    {
                        path: '/act/bingo/configs/',
                        component: React.lazy(() =>
                            import('../components/act/BingoConfig')
                        ),
                        exact: true,
                        breadcrumbName: 'Bingo活動設定',
                    },
                    {
                        path: '/act/bingo/configs/edit',
                        component: React.lazy(() =>
                            import('../components/act/BingoConfig')
                        ),
                        exact: true,
                        breadcrumbName: 'Bingo活動設定新增',
                    },
                    {
                        path: '/act/bingo/configs/edit/:id',
                        component: React.lazy(() =>
                            import('../components/act/BingoConfig')
                        ),
                        exact: true,
                        breadcrumbName: 'Bingo活動設定編輯',
                    },
                ],
            },

            {
                path: '/act/bingo/taskSettings/',
                component: React.lazy(() =>
                    import('../components/act/BingoTaskSettings')
                ),
                exact: true,
                breadcrumbName: 'Bingo任務',
            },
            {
                path: '/act/bingo/taskSettings/edit/:id',
                component: React.lazy(() =>
                    import('../components/act/BingoTaskSettings')
                ),
                exact: true,
                breadcrumbName: 'Bingo任務編輯',
            },
            {
                path: '/act/bingo/awardSettings/',
                component: RoutesContainer,
                breadcrumbName: 'Bingo獎勵設定',
                routes: [
                    {
                        path: '/act/bingo/awardSettings/',
                        component: React.lazy(() =>
                            import('../components/act/BingoAwardSettings')
                        ),
                        exact: true,
                        breadcrumbName: 'Bingo獎勵設定',
                    },
                    {
                        path: '/act/bingo/awardSettings/edit/',
                        component: React.lazy(() =>
                            import('../components/act/BingoAwardSettings')
                        ),
                        exact: true,
                        breadcrumbName: 'Bingo獎勵設定編輯',
                    },
                ],
            },
        ],
    },

    {
        path: '/act/acePoker/',
        component: RoutesContainer,
        breadcrumbName: '尋找王牌',
        noLink: true,
        routes: [
            {
                path: '/act/acePoker/vipPairRates/',
                component: RoutesContainer,
                breadcrumbName: 'Poker成對機率',
                routes: [
                    {
                        path: '/act/acePoker/vipPairRates/',
                        component: React.lazy(() =>
                            import('../components/act/acePoker/VipPairRates')
                        ),
                        breadcrumbName: 'Poker成對機率',
                        exact: true,
                    },
                    {
                        path: '/act/acePoker/vipPairRates/edit',
                        component: React.lazy(() =>
                            import('../components/act/acePoker/VipPairRates')
                        ),
                        breadcrumbName: 'Poker成對機率編輯',
                        exact: true,
                    },
                ],
            },
            {
                path: '/act/acePoker/vipAwardRates/',
                component: RoutesContainer,
                breadcrumbName: 'Poker點數中獎機率',
                routes: [
                    {
                        path: '/act/acePoker/vipAwardRates/',
                        component: React.lazy(() =>
                            import('../components/act/acePoker/VipAwardRates')
                        ),
                        breadcrumbName: 'Poker點數中獎機率',
                        exact: true,
                    },
                    {
                        path: '/act/acePoker/vipAwardRates/edit',
                        component: React.lazy(() =>
                            import('../components/act/acePoker/VipAwardRates')
                        ),
                        breadcrumbName: 'Poker點數中獎機率',
                        exact: true,
                    },
                ],
            },
            {
                path: '/act/acePoker/chipChangedLogs/',
                component: React.lazy(() =>
                    import('../components/act/acePoker/AcePokerChipChangedLogs')
                ),
                breadcrumbName: 'Poker籌碼變化紀錄',
                exact: true,
            },
            {
                path: '/act/acePoker/itemDeliveredLogs/',
                component: React.lazy(() =>
                    import(
                        '../components/act/acePoker/AcePokerItemDeliveredLogs'
                    )
                ),
                breadcrumbName: 'Poker獎勵發放紀錄',
                exact: true,
            },
            {
                path: '/act/acePoker/leaderBoard/',
                component: React.lazy(() =>
                    import('../components/act/acePoker/AcePokerLeaderBoard')
                ),
                breadcrumbName: 'Poker排行榜',
                exact: true,
            },
        ],
    },

    {
        path: '/act/acePoker2/',
        component: RoutesContainer,
        breadcrumbName: '尋找王牌2',
        noLink: true,
        routes: [
            {
                path: '/act/acePoker2/vipAwardRates/',
                component: RoutesContainer,
                breadcrumbName: 'Poker點數中獎機率',
                routes: [
                    {
                        path: '/act/acePoker2/vipAwardRates/',
                        component: React.lazy(() =>
                            import(
                                '../components/act/acePoker2/AcePoker2VipAwardRates'
                            )
                        ),
                        breadcrumbName: 'Poker點數中獎機率',
                        exact: true,
                    },
                    {
                        path: '/act/acePoker2/vipAwardRates/edit',
                        component: React.lazy(() =>
                            import(
                                '../components/act/acePoker2/AcePoker2VipAwardRates'
                            )
                        ),
                        breadcrumbName: '編輯Poker點數中獎機率',
                        exact: true,
                    },
                ],
            },
        ],
    },

    {
        path: '/act/chp/itemDeliveredLogs/',
        component: React.lazy(() =>
            import('../components/act/champagne/ChampagneItemDeliveredLogs')
        ),
        exact: true,
        breadcrumbName: '香檳樂獎勵發放紀錄',
    },

    {
        path: '/act/pointEvent/',
        component: RoutesContainer,
        breadcrumbName: '儲優活動頁',
        noLink: true,
        routes: [
            {
                path: '/act/pointEvent/',
                component: React.lazy(() =>
                    import('../components/act/pointEvent/PointEvent')
                ),
                exact: true,
                breadcrumbName: '儲優活動頁',
            },
            {
                path: '/act/pointEvent/edit/:id',
                component: React.lazy(() =>
                    import('../components/act/pointEvent/PointEventEdit')
                ),
                exact: true,
                breadcrumbName: '編輯儲值活動頁',
            },
            {
                path: '/act/pointEvent/add',
                component: React.lazy(() =>
                    import('../components/act/pointEvent/PointEventAdd')
                ),
                exact: true,
                breadcrumbName: '新增儲值活動頁',
            },
        ],
    },

    {
        path: '/act/event-info/',
        component: RoutesContainer,
        breadcrumbName: '活動說明內文',
        noLink: true,
        routes: [
            {
                path: '/act/event-info/',
                component: React.lazy(() =>
                    import('../components/act/eventInfo/EventInfo')
                ),
                exact: true,
                breadcrumbName: '活動說明內文',
            },
            {
                path: '/act/event-info/edit/:id',
                component: React.lazy(() =>
                    import('../components/act/eventInfo/EventInfoEdit')
                ),
                exact: true,
                breadcrumbName: '編輯活動說明內文',
            },
            {
                path: '/act/event-info/add',
                component: React.lazy(() =>
                    import('../components/act/eventInfo/EventInfoAdd')
                ),
                exact: true,
                breadcrumbName: '新增活動說明內文',
            },
        ],
    },

    {
        path: '/act/games',
        component: RoutesContainer,
        breadcrumbName: '遊戲編輯',
        noLink: true,
        routes: [
            {
                path: '/act/games',
                component: React.lazy(() => import('../components/act/Games')),
                exact: true,
                breadcrumbName: '遊戲編輯',
            },
        ],
    },

    {
        path: '/act/raffle-black-list',
        component: RoutesContainer,
        breadcrumbName: '抽獎機黑名單',
        noLink: true,
        routes: [
            {
                path: '/act/raffle-black-list',
                component: React.lazy(() =>
                    import('../components/act/raffle/RaffleBlackList')
                ),
                exact: true,
                breadcrumbName: '抽獎機黑名單',
            },
        ],
    },

    {
        path: '/act/entry-button',
        component: RoutesContainer,
        breadcrumbName: 'H5遊戲懸浮按鈕',
        noLink: true,
        routes: [
            {
                path: '/act/entry-button',
                component: React.lazy(() =>
                    import('../components/act/entryButton/EntryButtonList')
                ),
                exact: true,
                breadcrumbName: 'H5遊戲懸浮按鈕',
            },
        ],
    },

    {
        path: '/act/w-black-list',
        component: RoutesContainer,
        breadcrumbName: '許願池黑名單',
        noLink: true,
        routes: [
            {
                path: '/act/w-black-list',
                component: React.lazy(() =>
                    import('../components/act/wish/WishBlackList')
                ),
                exact: true,
                breadcrumbName: '許願池黑名單',
            },
        ],
    },

    {
        path: '/flash/',
        component: RoutesContainer,
        breadcrumbName: '活動',
        noLink: true,
        routes: [
            {
                path: '/flash/awardLogs/',
                component: React.lazy(() =>
                    import('../components/flash/AwardLogs')
                ),
                exact: true,
                breadcrumbName: '快閃獎勵紀錄',
            },
            {
                path: '/flash/gamePlayEvents/',
                component: React.lazy(() =>
                    import('../components/flash/GamePlayEvents')
                ),
                exact: true,
                breadcrumbName: '快閃事件',
            },
        ],
    },

    {
        path: '/ace/',
        component: RoutesContainer,
        breadcrumbName: '官網',
        noLink: true,
        routes: [
            {
                path: '/ace/events/',
                component: RoutesContainer,
                breadcrumbName: '熱門活動管理',
                routes: [
                    {
                        path: '/ace/events/',
                        component: React.lazy(() =>
                            import('../components/ace/Events')
                        ),
                        exact: true,
                        breadcrumbName: '熱門活動管理',
                    },
                    {
                        path: '/ace/events/edit/:id',
                        component: React.lazy(() =>
                            import('../components/ace/Event')
                        ),
                        exact: true,
                        breadcrumbName: '熱門活動編輯',
                    },
                    {
                        path: '/ace/events/edit/',
                        component: React.lazy(() =>
                            import('../components/ace/Event')
                        ),
                        exact: true,
                        breadcrumbName: '熱門活動新增',
                    },
                ],
            },
            {
                path: '/ace/products/',
                component: RoutesContainer,
                breadcrumbName: '產包管理',
                routes: [
                    {
                        path: '/ace/products/',
                        component: React.lazy(() =>
                            import('../components/ace/Products')
                        ),
                        exact: true,
                        breadcrumbName: '產包管理',
                    },
                    {
                        path: '/ace/products/edit/:id',
                        component: React.lazy(() =>
                            import('../components/ace/Product')
                        ),
                        exact: true,
                        breadcrumbName: '產包編輯',
                    },
                    {
                        path: '/ace/products/edit',
                        component: React.lazy(() =>
                            import('../components/ace/Product')
                        ),
                        exact: true,
                        breadcrumbName: '熱門產包新增',
                    },
                ],
            },
            {
                path: '/ace/bundles/',
                component: RoutesContainer,
                breadcrumbName: '人氣禮包管理',
                routes: [
                    {
                        path: '/ace/bundles/',
                        component: React.lazy(() =>
                            import('../components/ace/Bundles')
                        ),
                        exact: true,
                        breadcrumbName: '人氣禮包管理',
                    },
                    {
                        path: '/ace/bundles/edit/:id',
                        component: React.lazy(() =>
                            import('../components/ace/Bundle')
                        ),
                        exact: true,
                        breadcrumbName: '人氣禮包編輯',
                    },
                    {
                        path: '/ace/bundles/edit',
                        component: React.lazy(() =>
                            import('../components/ace/Bundle')
                        ),
                        exact: true,
                        breadcrumbName: '人氣禮包新增',
                    },
                ],
            },
            {
                path: '/ace/posts/',
                component: RoutesContainer,
                breadcrumbName: '活動公告管理',
                routes: [
                    {
                        path: '/ace/posts/',
                        component: React.lazy(() =>
                            import('../components/ace/Posts')
                        ),
                        exact: true,
                        breadcrumbName: '活動公告管理',
                    },
                    {
                        path: '/ace/posts/:id',
                        component: React.lazy(() =>
                            import('../components/ace/Post')
                        ),
                        exact: true,
                        breadcrumbName: '活動公告編輯',
                    },
                    {
                        path: '/ace/posts/edit',
                        component: React.lazy(() =>
                            import('../components/ace/Post')
                        ),
                        exact: true,
                        breadcrumbName: '活動公告新增',
                    },
                ],
            },
            {
                path: '/ace/banners/',
                component: RoutesContainer,
                breadcrumbName: 'Banner管理',
                routes: [
                    {
                        path: '/ace/banners/',
                        component: React.lazy(() =>
                            import('../components/ace/Banners')
                        ),
                        exact: true,
                        breadcrumbName: 'Banner管理',
                    },
                    {
                        path: '/ace/banners/edit/:bannerId',
                        component: React.lazy(() =>
                            import('../components/ace/Banner')
                        ),
                        exact: true,
                        breadcrumbName: 'Banner新增',
                    },
                    {
                        path: '/ace/banners/:id',
                        component: React.lazy(() =>
                            import('../components/ace/Banner')
                        ),
                        exact: true,
                        breadcrumbName: 'Banner編輯',
                    },
                ],
            },

            {
                path: '/ace/new-banners/',
                component: RoutesContainer,
                breadcrumbName: 'NewBanner管理',
                routes: [
                    {
                        path: '/ace/new-banners/',
                        component: React.lazy(() =>
                            import('../components/ace/newBanner/NewBanners')
                        ),
                        exact: true,
                        breadcrumbName: 'NewBanner管理',
                    },
                    {
                        path: '/ace/new-banners/edit/:bannerId',
                        component: React.lazy(() =>
                            import('../components/ace/newBanner/NewBanner')
                        ),
                        exact: true,
                        breadcrumbName: 'NewBanner新增',
                    },
                    {
                        path: '/ace/new-banners/:id',
                        component: React.lazy(() =>
                            import('../components/ace/newBanner/NewBanner')
                        ),
                        exact: true,
                        breadcrumbName: 'NewBanner編輯',
                    },
                ],
            },

            {
                path: '/ace/game-info/',
                component: RoutesContainer,
                breadcrumbName: '遊戲內容管理',
                routes: [
                    {
                        path: '/ace/game-info/',
                        component: React.lazy(() =>
                            import('../components/ace/GameInfos')
                        ),
                        exact: true,
                        breadcrumbName: '遊戲內容管理',
                    },
                    {
                        path: '/ace/game-info/:gameId',
                        component: React.lazy(() =>
                            import('../components/ace/GameInfo')
                        ),
                        exact: true,
                        breadcrumbName: '遊戲內容新增',
                    },
                    {
                        path: '/ace/banners/:gameId',
                        component: React.lazy(() =>
                            import('../components/ace/GameInfo')
                        ),
                        exact: true,
                        breadcrumbName: '遊戲內容編輯',
                    },
                ],
            },
            {
                path: '/ace/gift-pack/',
                component: RoutesContainer,
                breadcrumbName: '官網禮包底圖設定',
                routes: [
                    {
                        path: '/ace/gift-pack/',
                        component: React.lazy(() =>
                            import('../components/ace/giftPack/GiftPack')
                        ),
                        exact: true,
                        breadcrumbName: '官網禮包底圖設定',
                    },
                    {
                        path: '/ace/gift-pack/:id',
                        component: React.lazy(() =>
                            import('../components/ace/giftPack/EditGiftPack')
                        ),
                        exact: true,
                        breadcrumbName: '官網禮包底圖設定',
                    },
                ],
            },
        ],
    },

    {
        path: '/adm/',
        component: RoutesContainer,
        breadcrumbName: '管理',
        noLink: true,
        routes: [
            {
                path: '/adm/permission',
                component: React.lazy(() =>
                    import('../components/adm/permission/Permission')
                ),
                exact: true,
                breadcrumbName: '權限管理',
            },
            {
                path: '/adm/report',
                component: React.lazy(() =>
                    import('../components/adm/report/Report')
                ),
                exact: true,
                breadcrumbName: '報表查詢',
            },
            {
                path: '/adm/edit-password',
                component: React.lazy(() =>
                    import('../components/adm/user/EditPassword')
                ),
                exact: true,
                breadcrumbName: '密碼變更',
            },
            {
                path: '/adm/acl-users/',
                component: React.lazy(() =>
                    import('../components/ace/AclUsers')
                ),
                exact: true,
                breadcrumbName: '黑白名單',
            },
            {
                path: '/adm/redis/',
                component: React.lazy(() => import('../components/adm/Redis')),
                exact: true,
                breadcrumbName: 'Redis查詢',
            },
        ],
    },

    {
        path: '/permission-deny',
        component: React.lazy(() => import('../components/PermissionDeny')),
        exact: true,
        breadcrumbName: '無此權限',
    },
];

export default routes;
