import io from 'socket.io-client';
import { push } from 'connected-react-router';
import popupActionType from './actionTypes/popupActionTypes';
import { POPUP_TYPE } from './reducers/popupReducer';

const EVENT_NAME = 'action';
let socket = null;
let store = null;

function init(reduxStore) {
    store = reduxStore;

    const socketConfig =
        process.env.NODE_ENV === 'production'
            ? { transports: ['websocket'] }
            : {};
    socket = io(socketConfig);

    socket.on(EVENT_NAME, (action) => {
        const { type, data } = action;
        // console.log({ type, data });
        // 若錯誤有包含 message, 跳出警告視窗
        if (type.endsWith('error') && data.message) {
            store.dispatch({
                type: popupActionType.SET_POPUP,
                data: {
                    type: POPUP_TYPE.ERROR,
                    message: data.message,
                },
            });
        } else if (data?.alert) {
            // console.log({ alert: data.alert });
            // 有訊息要告知
            store.dispatch({
                type: popupActionType.SET_POPUP,
                data: {
                    type: POPUP_TYPE.ERROR,
                    message: data.alert,
                },
            });
        }
        store.dispatch(action);
    });

    // socket.emit(EVENT_NAME, { url: '/', type: authActionType.CHECK_LOGIN, rType: authActionType.SET_DATA });

    // socket.emit('action', { url: '/', type: actActionType.FETCH_ACTIVITIES, rType: actActionType.SET_DATA });

    socket.on('PERMISSION_DENY', () =>
        reduxStore.dispatch(push('/permission-deny'))
    );
    ['disconnect', 'connect_failed', 'connect'].forEach((event) => {
        // eslint-disable-next-line no-console
        socket.on(event, () => console.log(`websocket ${event}`, socket.id));
    });
}

function emit(action, cb) {
    if (socket === null) {
        throw new Error('socket is null');
    }
    action.url = window.location.pathname;
    store.dispatch({ type: action.type });
    if (cb) {
        socket.emit('action', action, cb);
    } else {
        socket.emit('action', action);
    }
}

export { init, emit };
