const ActionTypes = {
    LOADING: 'dragon.loading',
    ERROR: 'dragon.error',
    SET_DATA: 'dragon.set_data',

    FETCH_AWARD_DELIVERY: 'dragon.fetchAwardDelivery',

};

module.exports = ActionTypes;
