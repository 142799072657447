import ActionTypes from '../actionTypes/popupActionTypes';

const actions = {
    setPopup: (popup) => (dispatch) => {
        dispatch({ type: ActionTypes.SET_POPUP, data: popup });
    },
    hidePopup: () => (dispatch) => {
        dispatch({ type: ActionTypes.HIDE_POPUP });
    },
};

export default actions;
