const ActionTypes = {
    LOADING: 'aceLottery.loading',
    ERROR: 'aceLottery.error',
    SET_DATA: 'aceLottery.set_data',

    FETCH_AWARDS: 'aceLottery.awards',
    FETCH_LEADER_BOARD: 'aceLottery.fetch_leader_board',
    FETCH_CHIP_CHANGED_LOGS: 'aceLottery.fetch_chip_changed_logs',
    FETCH_ITEM_DELIVERED_LOGS: 'aceLottery.fetchItemDeliveredLogs',
    FETCH_ACHIEVED_LOGS: 'aceLottery.fetchAchievedLogs',
};

module.exports = ActionTypes;
