const ActionTypes = {
    LOADING: 'eventInfo.loading',
    ERROR: 'eventInfo.error',
    SET_DATA: 'eventInfo.set_data',

    FETCH_EVENT_INFO_ALL: 'eventInfo.fetchEventInfoAll',
    FIND_EVENT_INFO_BY_ID: 'eventInfo.findEventInfoById',
    ADD_EVENT_INFO: 'eventInfo.addEventInfo',
    UPDATE_EVENT_INFO: 'eventInfo.updateEventInfo',
    DELETE_EVENT_INFO: 'eventInfo.deleteEventInfo',
};

module.exports = ActionTypes;
