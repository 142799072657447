import { push } from 'connected-react-router';
import axios from 'axios';
import ActionTypes from '../actionTypes/actActionTypes';
import { emit } from '../socket';
import { SET_POPUP } from '../actionTypes/popupActionTypes';
import { POPUP_TYPE } from '../reducers/popupReducer';

const actions = {
    fetchActivities() {
        return (dispatch) => {
            dispatch({ type: ActionTypes.LOADING });
            emit({
                type: ActionTypes.FETCH_ACTIVITIES,
                rType: ActionTypes.SET_DATA,
                eType: ActionTypes.ERROR,
            });
        };
    },
    fetchActivity(id) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.LOADING });
            emit({
                type: ActionTypes.FETCH_ACTIVITY,
                rType: ActionTypes.SET_DATA,
                eType: ActionTypes.ERROR,
                args: id,
            });
        };
    },
    fetchCopyActivity(id) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.LOADING });
            emit({
                type: ActionTypes.FETCH_ACTIVITY,
                rType: ActionTypes.SET_COPY_ACTIVITY,
                eType: ActionTypes.ERROR,
                args: id,
            });
        };
    },
    deleteActivity(id) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.LOADING });
            emit({
                type: ActionTypes.DELETE_ACTIVITY,
                rType: ActionTypes.SET_DATA,
                eType: ActionTypes.ERROR,
                args: id,
            });
        };
    },
    saveActivity(form) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.LOADING });
            emit(
                {
                    type: ActionTypes.SAVE_ACTIVITY,
                    rType: ActionTypes.SET_DATA,
                    eType: ActionTypes.ERROR,
                    args: form,
                },
                () => dispatch(push('/act/activities/#' + form.type))
            );
        };
    },
    saveRafflePreselect(form) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.LOADING });
            emit({
                type: ActionTypes.SAVE_RAFFLE_PRESELECT,
                rType: ActionTypes.SET_DATA,
                eType: ActionTypes.ERROR,
                args: form,
            });
        };
    },
    // 儲存本次抽獎券總數
    updateRaffleTotalTicketsSize(form) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.LOADING });
            emit({
                type: ActionTypes.UPDATE_RAFFLE_TOTAL_TICKETS_SIZE,
                rType: ActionTypes.SET_DATA,
                eType: ActionTypes.ERROR,
                args: form,
            });
        };
    },
    fetchBingoAwardSettings() {
        return (dispatch) => {
            dispatch({ type: ActionTypes.LOADING });
            emit({
                type: ActionTypes.FETCH_BINGO_AWARD_SETTINGS,
                rType: ActionTypes.SET_DATA,
                eType: ActionTypes.ERROR,
            });
        };
    },
    saveBingoAwardSettings(form) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.LOADING });
            emit(
                {
                    type: ActionTypes.SAVE_BINGO_AWARD_SETTINGS,
                    rType: ActionTypes.SET_DATA,
                    eType: ActionTypes.ERROR,
                    args: form,
                },
                () => dispatch(push('/act/bingo/awardSettings/'))
            );
        };
    },

    fetchVipPairRates() {
        return (dispatch) => {
            dispatch({ type: ActionTypes.LOADING });
            emit({
                type: ActionTypes.FETCH_VIP_PAIR_RATES,
                rType: ActionTypes.SET_DATA,
                eType: ActionTypes.ERROR,
            });
        };
    },
    saveVipPairRates(form) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.LOADING });
            emit(
                {
                    type: ActionTypes.SAVE_VIP_PAIR_RATES,
                    rType: ActionTypes.SET_DATA,
                    eType: ActionTypes.ERROR,
                    args: form,
                },
                () => dispatch(push('/act/acePoker/vipPairRates/'))
            );
        };
    },

    fetchVipAwardRates() {
        return (dispatch) => {
            dispatch({ type: ActionTypes.LOADING });
            emit({
                type: ActionTypes.FETCH_VIP_AWARD_RATES,
                rType: ActionTypes.SET_DATA,
                eType: ActionTypes.ERROR,
            });
        };
    },
    saveVipAwardRates(form) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.LOADING });
            emit(
                {
                    type: ActionTypes.SAVE_VIP_AWARD_RATES,
                    rType: ActionTypes.SET_DATA,
                    eType: ActionTypes.ERROR,
                    args: form,
                },
                () => dispatch(push('/act/acePoker/vipAwardRates/'))
            );
        };
    },

    fetchAcePoker2VipAwardRates() {
        return (dispatch) => {
            dispatch({ type: ActionTypes.LOADING });
            emit({
                type: ActionTypes.FETCH_ACE_POKER2_VIP_AWARD_RATES,
                rType: ActionTypes.SET_DATA,
                eType: ActionTypes.ERROR,
            });
        };
    },
    saveAcePoker2VipAwardRates(form) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.LOADING });
            emit(
                {
                    type: ActionTypes.SAVE_ACE_POKER2_VIP_AWARD_RATES,
                    rType: ActionTypes.SET_DATA,
                    eType: ActionTypes.ERROR,
                    args: form,
                },
                () => dispatch(push('/act/acePoker2/vipAwardRates/'))
            );
        };
    },

    syncItems(form) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.LOADING });
            emit({
                type: ActionTypes.SYNC_ITEMS,
                rType: ActionTypes.SET_DATA,
                eType: ActionTypes.ERROR,
                args: form,
            });
        };
    },
    syncProducts(form) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.LOADING });
            emit({
                type: ActionTypes.SYNC_PRODUCTS,
                rType: ActionTypes.SET_DATA,
                eType: ActionTypes.ERROR,
                args: form,
            });
        };
    },
    // 上傳抽獎券名單
    uploadRaffleList({ key, value }) {
        const formData = new FormData();
        formData.append('upload', value);

        const authOptions = {
            method: 'POST',
            url: '/api/upload/raffleList',
            data: formData,
            headers: {
                'content-type': 'multipart/form-data',
            },
        };

        return (dispatch) => {
            dispatch({
                type: ActionTypes.LOADING,
            });

            return axios(authOptions)
                .then((response) => {
                    const { url } = response.data;
                    dispatch({
                        type: ActionTypes.SET_DATA,
                        data: { uploadImage: url },
                    });
                })
                .catch((error) => {
                    const { message } = error.response.data;

                    dispatch({
                        type: ActionTypes.ERROR,
                        data: { error: message },
                    });

                    dispatch({
                        type: SET_POPUP,
                        data: { type: POPUP_TYPE.ERROR, message },
                    });
                });
        };
    },

    // 儲存抽獎券名單
    saveRaffleTickets(form) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.LOADING });
            emit({
                type: ActionTypes.SAVE_RAFFLE_TICKETS,
                rType: ActionTypes.SET_DATA,
                eType: ActionTypes.ERROR,
                args: form,
            });
        };
    },

    // 修改預選名單
    updateRafflePreselect(form) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.LOADING });
            emit({
                type: ActionTypes.UPDATE_RAFFLE_PRESELECT,
                rType: ActionTypes.SET_DATA,
                eType: ActionTypes.ERROR,
                args: form,
            });
        };
    },

    // 修改預選名單
    deleteRafflePreselect(form) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.LOADING });
            emit({
                type: ActionTypes.DELETE_RAFFLE_PRESELECT,
                rType: ActionTypes.SET_DATA,
                eType: ActionTypes.ERROR,
                args: form,
            });
        };
    },

    fetchRaffleTicketsSize(actId) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.LOADING });
            emit({
                type: ActionTypes.FETCH_RAFFLE_TICKETS_SIZE,
                rType: ActionTypes.SET_DATA,
                eType: ActionTypes.ERROR,
                args: actId,
            });
        };
    },

    deleteWinners(actId) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.LOADING });
            emit({
                type: ActionTypes.DELETE_WINNERS,
                rType: ActionTypes.SET_DATA,
                eType: ActionTypes.ERROR,
                args: actId,
            });
        };
    },
    fetchRaffleBlackList() {
        return (dispatch) => {
            dispatch({ type: ActionTypes.LOADING });
            emit({
                type: ActionTypes.FETCH_RAFFLE_BLACK_LIST,
                rType: ActionTypes.SET_DATA,
                eType: ActionTypes.ERROR,
            });
        };
    },
    saveRaffleBlackList(uids) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.LOADING });
            emit({
                type: ActionTypes.SAVE_RAFFLE_BLACK_LIST,
                rType: ActionTypes.SET_DATA,
                eType: ActionTypes.ERROR,
                args: uids,
            });
        };
    },
    deleteRaffleBlackList(uid) {
        return (dispatch) => {
            dispatch({ type: ActionTypes.LOADING });
            emit({
                type: ActionTypes.DELETE_RAFFLE_BLACK_LIST,
                rType: ActionTypes.SET_DATA,
                eType: ActionTypes.ERROR,
                args: uid,
            });
        };
    },
    fetchAwardItems() {
        return (dispatch) => {
            dispatch({ type: ActionTypes.LOADING });
            emit({
                type: ActionTypes.FETCH_AWARD_ITEMS,
                rType: ActionTypes.SET_DATA,
                eType: ActionTypes.ERROR,
            });
        };
    },
    fetchProducts() {
        return (dispatch) => {
            dispatch({ type: ActionTypes.LOADING });
            emit({
                type: ActionTypes.FETCH_PRODUCTS,
                rType: ActionTypes.SET_DATA,
                eType: ActionTypes.ERROR,
            });
        };
    },
};

export default actions;
