const ActionTypes = require('../actionTypes/popupActionTypes');

export const POPUP_TYPE = {
    ERROR: 'error',
    CONFIRM: 'confirm',
    DEFAULT: 'defult',
};

// type: error, alert, confirm
const initState = {
    popup: {
        type: '',
        message: '',
    },
    isOpen: false,
};

function reducer(state = initState, action) {
    const newState = {};
    Object.assign(newState, state);
    switch (action.type) {
        case ActionTypes.SET_POPUP:
            newState.popup = action.data;
            newState.isOpen = true;
            break;
        case ActionTypes.HIDE_POPUP:
            newState.popup = null;
            newState.isOpen = false;
            break;
        default:
            break;
    }

    return newState;
}

export default reducer;
