const ActionTypes = {
    LOADING: 'treasure.loading',
    ERROR: 'treasure.error',
    SET_DATA: 'treasure.set_data',

    FETCH_POINTS_CHANGE: 'treasure.fetchPointsChange',
    FETCH_ITEM_DELIVERED: 'treasure.fetchItemDelivered',
    FETCH_RANK_LIST: 'treasure.fetchRankList',
};

module.exports = ActionTypes;
