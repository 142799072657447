const ActionTypes = {
    LOADING: 'boss.loading',
    ERROR: 'boss.error',
    SET_DATA: 'boss.set_data',

    UPLOAD_FILE: 'boss.uploadFile',
    FETCH_BOSS_PROGRESS: 'boss.fetchBossProgress',
    FETCH_ITEM_DELIVERED_LOG: 'boss.fetchItemDeliveredLog',
    FETCH_KILL_TIME_LOG: 'boss.fetchKillTimeLog',
    FETCH_RANK_LIST: 'boss.fetchRankList',
};

module.exports = ActionTypes;
