const ActionTypes = {
    LOADING: 'post.loading',
    ERROR: 'post.error',
    SET_DATA: 'post.set_data',

    FETCH_POSTS: 'post.fetchPosts',
    FETCH_POST: 'post.fetchPost',
    SAVE_POST: 'post.savePost',
};

module.exports = ActionTypes;
