const ActionTypes = {
    LOADING: 'product.loading',
    ERROR: 'product.error',
    SET_DATA: 'product.set_data',

    FETCH_PRODUCTS: 'product.fetchProducts',
    FETCH_PRODUCT: 'product.fetchProduct',
    SAVE_PRODUCT: 'product.saveProduct',
};

module.exports = ActionTypes;
