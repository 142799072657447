const ActionTypes = {
    LOADING: 'acePoker2.loading',
    ERROR: 'acePoker2.error',
    SET_DATA: 'acePoker2.set_data',

    FETCH_LEADER_BOARD: 'acePoker2.fetchLeaderBoard',
    FETCH_CHIP_CHANGED_LOGS: 'acePoker2.fetchChipChangedLogs',
    FETCH_ITEM_DELIVERED_LOGS: 'acePoker2.fetchItemDeliveredLogs',

};

module.exports = ActionTypes;
