const ActionTypes = {
    LOADING: 'worldCup.loading',
    ERROR: 'worldCup.error',
    SET_DATA: 'worldCup.set_data',

    FETCH_PRE_ITEM_DELIVERED: 'worldCup.fetchPreItemDelivered',
    FETCH_MAIN_ITEM_DELIVERED: 'worldCup.fetchMainItemDelivered',
    FETCH_TOP_16_GUESS: 'worldCup.fetchTop16Guess',
    FETCH_MAIN_GUESS: 'worldCup.fetchMainGuess',
    FETCH_RANK_LIST: 'worldCup.fetchRankList',
    FETCH_RANK_LIST_WEEKLY: 'worldCup.fetchRankListWeekly',
};

module.exports = ActionTypes;
